"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MIN_LABEL_LENGTH = exports.MAX_LABEL_LENGTH = exports.MIN_MARGIN_LEFT = exports.MAX_MARGIN_LEFT = exports.performanceThreshold = exports.performanceStep = exports.ConcentrationRange = void 0;
__exportStar(require("./colors"), exports);
exports.ConcentrationRange = ['0-1', '1-5', '5-10', '10-15', '15-20', '20-100'];
exports.performanceStep = 10;
exports.performanceThreshold = 10;
exports.MAX_MARGIN_LEFT = 250;
exports.MIN_MARGIN_LEFT = 110;
exports.MAX_LABEL_LENGTH = 30;
exports.MIN_LABEL_LENGTH = 12;
