"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TOPIC_THRESHOLD = exports.LegendKey = exports.CARD_PADDING = exports.REPORT_FOOTER_HEIGHT = exports.SUB_TOPIC_HEIGHT = exports.ONE_THIRD_PAGE_WIDTH = exports.HALF_PAGE_HEIGHT = exports.HALF_PAGE_WIDTH = void 0;
exports.HALF_PAGE_WIDTH = 503;
exports.HALF_PAGE_HEIGHT = 220;
exports.ONE_THIRD_PAGE_WIDTH = 320;
exports.SUB_TOPIC_HEIGHT = 18;
exports.REPORT_FOOTER_HEIGHT = 50;
exports.CARD_PADDING = 10;
exports.LegendKey = function (LegendKey) {
    LegendKey["GENDER"] = "gender";
    LegendKey["HOME_COUNTRY"] = "home_country";
    LegendKey["DOMESTIC_MIGRANT"] = "domestic_migrant";
    LegendKey["PERFORMANCE"] = "performance";
    LegendKey["CONCENTRATION"] = "concentration";
    LegendKey["FEEDBACK_QUESTIONS"] = "feedback_questions";
    LegendKey["KNOWLEDGE_GAIN"] = "knowledge_gain";
    return LegendKey;
}({});
exports.TOPIC_THRESHOLD = 10;
